<template>
  <Container>
    <div class="w_100">
      <div class="item">
        <label class="d_flex mb_75rem">
          <div class="icon">
            <img src="../../../assets/images/public/ic_my_idea_feedback.webp" alt="" />
          </div>
          <span>反馈内容</span>
        </label>
        <textarea cols="30" rows="10" placeholder="最多输入200字..." class="form_control" v-model="problem" maxlength="200"
          minlength="10"></textarea>
      </div>
      <div class="item">
        <label class="d_flex mb_75rem">
          <div class="icon">
            <img src="../../../assets/images/public/ic_my_idea_feedback.webp" alt="" />
          </div>
          <span>问题类型</span>
        </label>
        <select class="form_control" v-model="problemType">
          <option :value="null" disabled>请选择问题类型</option>
          <option :value="option.key" v-for="option in options" :key="option.key">
            {{ option.value }}
          </option>
        </select>
      </div>
      <div class="item">
        <label class="d_flex mb_75rem">
          <div class="icon">
            <img src="../../../assets/images/public/ic_my_idea_wechat.webp" alt="" />
          </div>
          <span>联系方式</span>
        </label>
        <input type="tel" class="form_control" placeholder="请输入您的联系方式" v-model="mobile" />
      </div>
      <div class="btn_default btn_primary" @click="submitForm">提交</div>
    </div>
  </Container>
</template>

<script>
import { mapActions } from "vuex";
import Container from "../components/Container";
import { Message } from "element-ui";
export default {
  name: "security",
  components: {
    Container
  },
  data() {
    return {
      options: [
        {
          key: "0",
          value: "操作问题"
        },
        {
          key: "1",
          value: "数据问题"
        },
        {
          key: "2",
          value: "其他"
        }
      ],
      problem: "",
      mobile: "",
      problemType: null
    };
  },
  methods: {
    ...mapActions("problem", ["postProblemLogs"]),
    submitForm() {
      if (
        this.problem === "" ||
        this.mobile === "" ||
        this.problemType === null
      ) {
        return;
      }
      if (this.problem.length < 10) {
        Message({
          message: "最少输入10个字",
          iconClass: "x",
          center: true,
          customClass: "error_message"
        });
        return;
      }
      let data = {
        problem: this.problem.substr(0, 200),
        mobile: this.mobile,
        problemType: this.problemType
      };
      this.postProblemLogs(data);
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.btn_default {
  max-width: 367px;
  margin: 0 auto;
}

.w_100 {
  width: 100%;
}

.d_flex {
  display: flex;
  align-items: center;
  width: 100%;
}

.justify_content_between {
  justify-content: space-between;
}

.item {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 0.75em;
}

.icon {
  display: block;
  width: 1.25rem;
  margin-right: 0.25em;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.border_bottom {
  border-bottom: 0.75px solid #d4d4d481;
}

.form_label {
  margin-right: 0.5rem;
}

.form_control {
  flex: 1;
  padding: 0.75rem 0.75rem;
  border: 1px solid var(--light-gray);
}

::placeholder {
  color: var(--light-gray);
  font-size: 1rem;
}

input:focus {
  outline: none;
}

.btn {
  &_default {
    display: inline-block;
    align-self: center;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: var(--light-gray);
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 1.25rem;
    width: 80vw;
    margin-top: 3rem;
  }

  &_primary {
    color: white;
    background-color: var(--orange);
    max-width: 364px;
  }
}

.mb_75rem {
  margin-bottom: 0.75rem;
}
</style>
